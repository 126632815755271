<template>
  <b-card-code title="Visualisation des points de distributions">
    <div>
      <b-button
        variant="outline-primary"
        @click="showR1"
      >
        Réseau de distribution 1
      </b-button>
      <b-button
        class="ml-1"
        variant="outline-primary"
        @click="showR2"
      >
        Réseau de distribution 2
      </b-button>
      <hr>

      <!--      <b-row class="mt-2">-->
      <!--        <b-col md="6">-->
      <!--          <b-form-group label="Contol Layers position: ">-->
      <!--            <b-form-select-->
      <!--              v-model="layersPosition"-->
      <!--              :options="Positions"-->
      <!--            />-->
      <!--          </b-form-group>-->

      <!--        </b-col>-->
      <!--        <b-col md="6">-->
      <!--          <b-form-group label="Attribution position:">-->
      <!--            <b-form-select-->
      <!--              v-model="attributionPosition"-->
      <!--              :options="Positions"-->
      <!--            />-->
      <!--          </b-form-group>-->
      <!--        </b-col>-->
      <!--      </b-row>-->

    </div>

    <!-- map -->
    <l-map
      :zoom.sync="zoom"
      :options="mapOptions"
      :center="center"
      :bounds="bounds"
      :min-zoom="minZoom"
      :max-zoom="maxZoom"
      style="height: 500px; width: 100%"
    >
      <l-control-layers
        :position="layersPosition"
        :collapsed="false"
        :sort-layers="true"
      />
      <l-tile-layer
        v-for="tileProvider in tileProviders"
        :key="tileProvider.name"
        :name="tileProvider.name"
        :visible="tileProvider.visible"
        :url="tileProvider.url"
        :attribution="tileProvider.attribution"
        :token="token"
        layer-type="base"
      />
      <l-control-zoom :position="zoomPosition" />
      <l-control-attribution
        :position="attributionPosition"
        :prefix="attributionPrefix"
      />
      <l-control-scale :imperial="imperial" />
      <l-marker
        v-for="marker in markers"
        :key="marker.id"
        :visible="marker.visible"
        :draggable="marker.draggable"
        :lat-lng.sync="marker.position"
        :icon="marker.icon"
        @click="alert(marker)"
      >
        <l-popup :content="marker.tooltip" />
        <l-tooltip :content="marker.tooltip" />
      </l-marker>
      <l-layer-group
        v-for="item in reseau1"
        :key="item.id"
        :visible.sync="item.visible"
        layer-type="overlay"
        name="Réseau 1"
      >
        <l-layer-group :visible="item.markersVisible">
          <v-marker-cluster>
            <l-marker
              v-for="marker in item.markers"
              :key="marker.id"
              :visible="marker.visible"
              :draggable="marker.draggable"
              :lat-lng="marker.position"
            />
          </v-marker-cluster>
        </l-layer-group>
        <!--        <l-polyline-->
        <!--          :lat-lngs="item.polyline.points"-->
        <!--          :visible="item.polyline.visible"-->
        <!--          @click="alert(item.polyline)"-->
        <!--        />-->
      </l-layer-group>
      <l-layer-group
        v-for="item in reseau2"
        :key="item.id"
        :visible.sync="item.visible"
        layer-type="overlay"
        name="Réseau 2"
      >
        <l-layer-group :visible="item.markersVisible">
          <v-marker-cluster>
            <l-marker
              v-for="marker in item.markers"
              :key="marker.id"
              :visible="marker.visible"
              :draggable="marker.draggable"
              :lat-lng="marker.position"
            />
          </v-marker-cluster>
        </l-layer-group>
        <!--        <l-polyline-->
        <!--          :lat-lngs="item.polyline.points"-->
        <!--          :visible="item.polyline.visible"-->
        <!--          @click="alert(item.polyline)"-->
        <!--        />-->
      </l-layer-group>
    </l-map>

    <template #code>
      {{ codeLayerGroup }}
    </template>
  </b-card-code>
</template>

<script>

/* eslint-disable global-require */
import BCardCode from '@/@core/components/b-card-code/BCardCode.vue'
import {
  BButton, BFormSelect, BRow, BCol, BFormGroup,
} from 'bootstrap-vue'
import {
  LMap,
  LTileLayer,
  LMarker,
  LPolyline,
  LLayerGroup,
  LTooltip,
  LPopup,
  LControlZoom,
  LControlAttribution,
  LControlScale,
  LControlLayers,
} from 'vue2-leaflet'
import { latLngBounds, Icon } from 'leaflet'
import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster'
import './assets/MarkerCluster.css'
import './assets/MarkexrCluster.Default.css'
import { codeLayerGroup } from './code'

// eslint-disable-next-line no-underscore-dangle
delete Icon.Default.prototype._getIconUrl
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})

const tileProviders = [
  {
    name: 'OpenStreetMap',
    visible: true,
    attribution:
      '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
    url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
  },
  {
    name: 'OpenTopoMap',
    visible: false,
    url: 'https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png',
    attribution:
      'Map data: &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)',
  },
]

const markersReseau1 = []
const markersReseau2 = []

const latOuaga = 12.3714
const lngOuaga = -1.5333

// eslint-disable-next-line no-plusplus
for (let i = 0; i < 1000; i++) {
  const lat = latOuaga + (Math.random() - 0.5) / 10 // Ajoute un nombre aléatoire entre -0.05 et 0.05 à la latitude de Ouagadougou
  const lng = lngOuaga + (Math.random() - 0.5) / 10 // Ajoute un nombre aléatoire entre -0.05 et 0.05 à la longitude de Ouagadougou
  markersReseau1.push({ position: { lat, lng } })
}
// eslint-disable-next-line no-plusplus
for (let i = 0; i < 1000; i++) {
  const lat = latOuaga + (Math.random() - 0.5) / 10 // Ajoute un nombre aléatoire entre -0.05 et 0.05 à la latitude de Ouagadougou
  const lng = lngOuaga + (Math.random() - 0.5) / 10 // Ajoute un nombre aléatoire entre -0.05 et 0.05 à la longitude de Ouagadougou
  markersReseau2.push({ position: { lat, lng } })
}
console.log(markersReseau1.length)
export default {
  name: 'Example',
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPolyline,
    LLayerGroup,
    LTooltip,
    LPopup,
    LControlZoom,
    LControlAttribution,
    LControlScale,
    LControlLayers,
    BButton,
    BFormSelect,
    BRow,
    BCol,
    BFormGroup,
    BCardCode,
    'v-marker-cluster': Vue2LeafletMarkerCluster,
  },
  data() {
    return {
      codeLayerGroup,
      center: [-1.5333, 12.3714],
      opacity: 0.6,
      token: 'your token if using mapbox',
      mapOptions: {
        zoomControl: false,
        attributionControl: false,
        zoomSnap: true,
      },
      zoom: 1,
      minZoom: 1,
      maxZoom: 20,
      zoomPosition: 'topleft',
      attributionPosition: 'bottomright',
      layersPosition: 'topright',
      attributionPrefix: 'Vue2Leaflet',
      imperial: false,
      Positions: ['topleft', 'topright', 'bottomleft', 'bottomright'],
      tileProviders,
      markers: [
      ],
      polylines: [
        {
          id: 'p1',
          points: [
            { lat: 37.772, lng: -122.214 },
            { lat: 21.291, lng: -157.821 },
            { lat: -18.142, lng: -181.569 },
            { lat: -27.467, lng: -206.973 },
          ],
          visible: true,
        },
        {
          id: 'p2',
          points: [
            [-73.91, 40.78],
            [-87.62, 41.83],
            [-96.72, 32.76],
          ],
          visible: true,
        },
      ],
      reseau1: [
        {
          id: 's1',
          markers: markersReseau1,
          polyline: { points: markersReseau1, visible: true },
          visible: true,
          markersVisible: true,
        },
      ],
      reseau2: [
        {
          id: 's2',
          markers: markersReseau2,
          polyline: { points: markersReseau2, visible: true },
          visible: true,
          markersVisible: true,
        },
      ],
      bounds: latLngBounds(
        { lat: 51.476483373501964, lng: -0.14668464136775586 },
        { lat: 51.52948330894063, lng: -0.019140238291583955 },
      ),
      reseauxDistribution1: [],
      reseauxDistribution2: [],
      reseauxDistribution3: [],
      reseauxDistribution4: [],
    }
  },
  created() {

  },
  methods: {
    alert(item) {
      // eslint-disable-next-line no-alert
      alert(`this is ${JSON.stringify(item)}`)
    },
    addMarker() {
      const newMarker = {
        position: { lat: 50.5505, lng: -0.09 },
        draggable: true,
        visible: true,
      }
      this.markers.push(newMarker)
    },
    removeMarker(index) {
      this.markers.splice(index, 1)
    },
    showR1() {
      const bounds = latLngBounds(markersReseau1.map(o => o.position))
      this.bounds = bounds
    },
    showR2() {
      const bounds = latLngBounds(markersReseau2.map(o => o.position))
      this.bounds = bounds
    },
  },
}
</script>
